import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import FeaturedLayout from "components/FeaturedLayout/FeaturedLayout"

const PlayerTemplate = (props) =>  {
	const data = props.data.wpPlayer
	return (
		<Layout 
			path={data.uri} 
			seo={data.seo} 
		>
			<FeaturedLayout data={data} type="Player" uri={data.uri} />
		</Layout>
	)
}

export const playerQuery = graphql`
query($id: String!) {
	wpPlayer(id: {eq: $id}) {
		id
		title
		uri
		date(formatString: "MMMM DD, Y")
		seo {
			metaKeywords
			metaDesc
			title
			breadcrumbs {
				text
				url
			}
		}
		...playerTemplateQuery
	}
}
`

export default PlayerTemplate